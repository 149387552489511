@import "../../../../assets/globalStyles/colors.scss";
@import "../../../../assets/globalStyles/breakpoints.scss";

.nav{
  .logo-container {
    .logo-nav {
      width: 60px;
      margin: auto;
      height: auto;
    }
  }
  .button-container {
    width: 40x;
    height: 40px;
  }
}
.ant-drawer {
  background-color: transparent
}
.ant-drawer-header{
  text-align: center;
  background-color: $dark-gray;
}
.ant-drawer-title{
  color: $light-gray;
  font-weight: 700;
}
.ant-drawer-body {
  background-color: $dark-gray;
  width: max-content;
}
.ant-btn-primary {
  vertical-align:middle;
  color: $light-gray;
  border-color: transparent;
  background-color: transparent;
  margin: auto;
}

.ant-btn-primary:hover {
  color: $light-gold;
  border-color: transparent;
  background-color: transparent;
}

.ant-btn-primary:active {
  border-color: transparent;
  background-color: transparent;
}

.icon {
  font-size: 20px; 
  vertical-align: top;
  @media only screen and (max-width: $md) {
    font-size: 25px;
  }
}
