@import "../../../assets/globalStyles/colors.scss";

.skeletonContainer{
  width: 100%;
  .skeleton{
    width: 100%;
    max-height: 200px;
  }
}

.divider{
  height: 2px;
  width: 34px;
  display: block;
  border: 0;
  border-top: 1px solid $dark-gold;
  background-color: $dark-gold;
  margin: 1em 0;
  padding: 0; 
}
