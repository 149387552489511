@import "../../../../assets/globalStyles/colors.scss";
@import "../../../../assets/globalStyles/breakpoints.scss";

.descriptionText {
    padding-top: 1%;
    padding-bottom: 3%;
    padding-left: 4%;
    .carModel {
        color: $dark-gray;
        width: 300px;
        @media only screen and (min-width: $xl){
            width: 20vw;
        }
        @media only screen and (min-width: $lg) and (max-width: $xl){
            width: 18vw;
        }
        @media only screen and (min-width: $md) and (max-width: $lg){
            width: 30vw;
        }
        @media only screen and (min-width: $sm) and (max-width: $md){
            width: 69vw;
        }
        padding-top: 1%;
        border-bottom: 1px solid $dark-gold;
        .brand {
            font-weight: bold;
        }
        .model {
            color: $dark-gray;
        }
    }
    .carModelDescription {
        padding-top: 5%;
        @media only screen and (min-width: $sm) and (max-width: $md){
            padding-top: 2%;
        }
        .text {
            font-size: small;
            @media only screen and (min-width: $lg){
                max-width: 20vw;
            }
            @media only screen and (min-width: $md) and (max-width: $lg){
                max-width: 26vw;
            }
        }
        .icon {
            width: 1.8rem;
            padding-right: 2%;
            color: $dark-gold;
        }
    }
}