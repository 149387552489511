.container {
  text-align:  justify;
  padding-left: 5%;
  padding-right: 5%;
  height: fit-content;
}

.slim{
  padding-bottom: 5%;
}

.addedPadding{
  padding-left: 15%;
  padding-right: 15%;
}