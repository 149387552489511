@import "../../../assets/globalStyles/colors.scss";

.divider:before {
  width: 25%;
  border-top: 2px solid $light-gold;
  content: "";
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
}

.divider{
  margin-top: 5px;
  display: block;
  font-size: 0;
  line-height: 0;
  height: 2px;
}