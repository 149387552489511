@import "../../../assets/globalStyles/colors.scss";
@import "../../../assets/globalStyles/breakpoints.scss";

$imageRatio: 0.605;

.modelsContainer {
    padding-top: 3%;
    width: 100vw;
    margin: auto;
    @media only screen and (min-width: $sm) and (max-width: $md){
        width: 70vw;
    }
    .modelsContent{
        border-bottom: 1px solid $dark-gold;
        background-color: $white-smoke;
        .imageContainer {
            text-align: center;
            background-color: $white-smoke;
            @media only screen and (min-width: $xl){
                min-height: $imageRatio * 22vw;
            }
            @media only screen and (min-width: $lg) and (max-width: $xl){
                min-height: $imageRatio * 30vw;
            }
            @media only screen and (min-width: $md) and (max-width: $lg){
                min-height: $imageRatio * 40vw;
            }
            @media only screen and (min-width: $sm) and (max-width: $md){
                min-height: $imageRatio * 50vw;
            }
            @media only screen and (max-width: $sm){
                min-height: $imageRatio * 60vw;
            }
            .image {
                padding-top: 5%;
                min-height: 20vh;
                @media only screen and (min-width: $xl){
                    width: 20vw;
                    max-height: $imageRatio * 22vw;
                }
                @media only screen and (min-width: $lg) and (max-width: $xl){
                    width: 20vw;
                    max-height: $imageRatio * 30vw;
                }
                @media only screen and (min-width: $md) and (max-width: $lg){
                    width: 30vw;
                    max-height: $imageRatio * 40vw;
                }
                @media only screen and (min-width: $sm) and (max-width: $md){
                    width: 40vw;
                    max-height: $imageRatio * 50vw;
                }
                @media only screen and (max-width: $sm){
                    width: 50vw;
                    min-width: 250px;
                    max-height: $imageRatio * 60vw;
                }
            }
            .infoText {
                padding-top: 2%;
                padding-bottom: 2%;
                .icon {
                    font-size: large;
                    padding-right: 1%;
                    color: $dark-gold;
                }
                .text {
                    font-weight: bold;
                    color: $dark-gold;
                }
            }
        }
        .prices{
            padding-top: 2%;
            padding-left: 4%;
            .priceText {
                color: $dark-gray;
            }
            .selectButtonContainer {
                padding-top: 3%;
                padding-bottom: 3%;
                text-align: center;
                .selectButton {
                    width: 17vw;
                    @media only screen and (min-width: $md) and (max-width: $lg){
                        width: 20vw;
                    }
                    @media only screen and (min-width: $sm) and (max-width: $md){
                        width: 64vw;
                        height: 40px;
                    }
                    @media only screen and (max-width: $sm){
                        width: 75vw;
                        height: 40px;
                    }
                }
            }
        }
    }
} 