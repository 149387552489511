@import "../../assets/globalStyles/colors.scss";
@import "../../assets/globalStyles/breakpoints.scss";

.marginTopSmall{
  margin-top: 3%;
}

.marginTopBig{
  margin-top: 5%;
}

.termsAndConditionsContainer{
  background-color: $light-gray;
  .termsAndConditions{
    padding-top: 3%;
    padding-bottom: 3%;
    .smallLetter{
      font-weight: lighter;
      margin-top: 3%;
    }
  }
}
.termsLink {
  color:$dark-gold;
  font-weight: bold;
}
.termsLink:hover {
  color:$light-gold;
}

.marginTopL{
  margin-top: 5vh;
}

.marginTopS {
  margin-top: 2vh;
}

.modelName {
  font-size: 2.3em;
  font-weight: bold;
  color: $dark-gold;

  @media only screen and (min-width: $xl) {
    left: 26vw;
  }

  @media only screen and (min-width: $lg) and (max-width: $xl) {
    left: 26vw;
  }

  @media only screen and (min-width: $md) and (max-width: $lg) {
    left: 20vw;
  }

  @media only screen and (min-width: $sm) and (max-width: $md) {
    left: 5vw;
  }

  @media only screen and (max-width: $sm) {
    left: 5vw;
  }
}
