@import "../../../assets/globalStyles/colors.scss";
@import "../../../assets/globalStyles/breakpoints.scss";

.NewsletterForm{
    margin: auto;
    width: fit-content;
    max-width: 90vw;
    padding: 2%;
    background-color: $light-gray;
    height: fit-content;
    text-align: center;
    .inputRow {
        margin: auto;
        width: fit-content;
        text-align: center;
        @media only screen and (min-width: $lg) {
            padding-right: 5%;
            }
        .col {
            .title{
                color: $dark-gray;
                font-size: medium;
                margin: 2%;
            }
        }
    }
}