@import "../../../assets/globalStyles/colors.scss";
@import "../../../assets/globalStyles/breakpoints.scss";

.ReservationForm {
 .default {
  width: fit-content;
  height: auto;
  background-color: rgba($dark-gray, 0.7); 
  color: $white-smoke;
  border: transparent;
  padding-bottom: 0px;
  margin-left: auto;
  margin-right: 8%;
  max-height: 100%;
  margin-top: 4%;
  margin-bottom: 0;
  @media only screen and (max-width: $md) {
    margin-right: auto;
    margin-top: 7%;
  }
  .title {
    margin: auto;
    color: $light-gold;
    font-size: 25px;
  }
  .subtitle {
    margin: auto;
    padding-bottom: 1%;
    color: $white-smoke;
    font-size: 15px;
    margin: auto;
  }
  .checktitle {
    margin: auto;
    padding-bottom: 1%;
    color: $white-smoke;
    font-size: 13px;
    margin: auto;
  }
.ant-checkbox-checked {
  .ant-checkbox-inner{
    background-color: $light-gold;
    border-color: $light-gold;
  }
  &:after {
    border-color: transparent;
    background: transparent;
  }
  &:focus {
    border-color: transparent;
    background: transparent;
  }
}
 }
 .sider {
  width: 70%;
  text-align: center;
  height: 100%;
  background-color: $warm-white; 
  color: $dark-gray;
  margin-right: auto;
  margin-left: auto;
  .title {
    margin: auto;
    color: $light-gold;
    font-size: 20px;
    margin-bottom: 2%;
  }
  .subtitle {
    margin: auto;
    padding-bottom: 1%;
    color: $dark-gray;
    font-size: 15px;
    margin: auto;
  }
  .checktitle {
    margin: auto;
    padding-bottom: 1%;
    color: $dark-gray;
    font-size: 13px;
    margin: auto;
  }
.ant-checkbox-checked {
  .ant-checkbox-inner{
    background-color: $light-gold;
    border-color: $light-gold;
  }
  &:after {
    border-color: transparent;
    background: transparent;
  }
  &:focus {
    border-color: transparent;
    background: transparent;
  }
}
 }
}