@import "../../../assets/globalStyles/colors.scss";
@import "../../../assets/globalStyles/breakpoints.scss";

$image-ratio: 0.605;

.modelsContent{
    background-color: $white-smoke;
    margin: auto;
    @media only screen and (min-width: $xl){
        height: 42vh;
        min-height: 300px;
        width: 22vw;
    }
    @media only screen and (min-width: $lg) and (max-width: $xl){
        height: 45vh;
        min-height: 320px;
        width: 30vw;
    }
    @media only screen and (min-width: $md) and (max-width: $lg){
        height: 55vh;
        min-height: 340px;
        width: 40vw;
    }
    @media only screen and (min-width: $sm) and (max-width: $md){
        height: 50vh;
        min-height: 325px;
        width: 50vw;
    }
    @media only screen and (max-width: $sm){
        height: 50vh;
        min-height: 300px;
        width: 60vw;
        min-width: 250px;
    }
    .image {
        @media only screen and (min-width: $xl){
            width: 22vw;
            max-height: $image-ratio * 22vw;
        }
        @media only screen and (min-width: $lg) and (max-width: $xl){
            width: 30vw;
            max-height: $image-ratio * 30vw;
        }
        @media only screen and (min-width: $md) and (max-width: $lg){
            width: 40vw;
            max-height: $image-ratio * 40vw;
        }
        @media only screen and (min-width: $sm) and (max-width: $md){
            width: 50vw;
            max-height: $image-ratio * 50vw;
        }
        @media only screen and (max-width: $sm){
            width: 60vw;
            min-width: 250px;
            max-height: $image-ratio * 60vw;
        }
    }
    .modelsInfo {
        padding-top: 3%;
        padding-left: 2.9%;
        .modelsText {
            color: $dark-gray;
            border-bottom: 1px solid $dark-gold;
            @media only screen and (min-width: $xl){
                width: 22vw
            }
            @media only screen and (min-width: $lg) and (max-width: $xl){
                width: 30vw
            }
            @media only screen and (min-width: $md) and (max-width: $lg){
                width: 40vw;
            }
            @media only screen and (min-width: $sm) and (max-width: $md){
                width: 50vw
            }
            @media only screen and (max-width: $sm){
                width: 60vw;
                min-width: 250px;
            }
            .brand {
                font-weight: bold;
            }
            .model {
                color: $dark-gray;
            }
        }
    }
    .imageContainer {
        @media only screen and (min-width: $xl){
            min-height: $image-ratio * 22vw;
        }
        @media only screen and (min-width: $lg) and (max-width: $xl){
            min-height: $image-ratio * 30vw;
        }
        @media only screen and (min-width: $md) and (max-width: $lg){
            min-height: $image-ratio * 40vw;
        }
        @media only screen and (min-width: $sm) and (max-width: $md){
            min-height: $image-ratio * 50vw;
        }
        @media only screen and (max-width: $sm){
            min-height: $image-ratio * 60vw;
        }
    }
}