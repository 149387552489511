@import "../../assets/globalStyles/colors.scss";
@import "../../assets/globalStyles/breakpoints.scss";

.app {
  text-align: center;

  .reservationContent {
    text-align: center;
    align-items: center;
    width: 100vw;
    max-height: 600px;
    overflow-y: hidden;
    z-index: 0;
    .reservationGrid{ 
      overflow-x: hidden;
      margin: auto;
      display: grid;
      .reservationImage{
        width: 100vw;
        margin: auto;
        height: auto;
        vertical-align: middle;
        grid-area: 1 / 1;
        
      }
      .reservationCard{
        grid-area: 1 / 1;
      }
    }

  }
    .catalogueContent {
    background-color: $warm-white;
    text-align: center;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .aboutUSContent {
    text-align: center;
    width: 100%;
    padding: 5px;
    
  }
  .brandsContent {
    height: fit-content;
    text-align: center;
    background: $warm-white;
    width: fit-content;
    .brandsImage{
      vertical-align: middle;
      margin: auto;
      max-width: 75vw;
    }
  }
  .newsletterContent{
    width: 100%;
    margin: auto;
    padding-top: 2%;
    padding-bottom: 2%;
  }

}

