@import "../../../../assets/globalStyles/colors.scss";

$image-ratio: 0.605;

.carInfo {
  :global(.ant-card-body) {
    padding: 0px;
    margin: auto;
  }
  .imageContainer{
    min-height: 220px;
    img {
      margin: auto;
      height: auto;
      max-width: 150px / $image-ratio;
    }
  }
  h5 {
    margin-top: 10px;
    padding-top: 2px;
    margin-bottom: 10px;
    border-top: 1px solid $dark-gold;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
  }
}