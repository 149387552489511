@import "../../../assets/globalStyles/colors.scss";
@import "../../../assets/globalStyles/breakpoints.scss";

.rentLayout{
  width: 100vw;
  min-height: 72vh;
  overflow-x: hidden;
  .content{
    overflow-y: scroll;
    max-height: 100vh;
    padding: 2vw;
    .sider{
      background-color: $warm-white;
    }
    // Hide scrollbar
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
  .noSider{
  }
}
