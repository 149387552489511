@import "../../../assets/globalStyles/colors.scss";

.prices{
    text-align: left;
    .priceText {
        color: $dark-gray;
    }
    .priceBeforeDiscountText {
        padding-bottom: 2%;
        text-decoration-line: line-through;
        text-decoration-style: solid;
        color: $medium-gray;
    }
}
.withMargin{
    padding-top: 3%;
}