@import "../../../../assets/globalStyles/colors.scss";
@import "../../../../assets/globalStyles/breakpoints.scss";

.stickyContainer {
    z-index: 100;
    position: fixed;
    bottom: 1%;
    right: 15px;
    height: fit-content;
    display: inline-block;
    
    .socialContainer {
        border-radius: 12px;
        padding: 1px;
        margin: 5px;
        width: max-content;
        height: max-content; 
      }
}
.socialIcon {
  color: $light-gold;
  font-size: 40px; 
  @media only screen and (max-width: $md) {
    font-size: 35px;
  }
}