@import "../../../../../assets/globalStyles/colors.scss";


.nav{

  .ant-menu {
    background-color: transparent;
    border: None;
    height: 100%;
    min-width: 600px;
  }
  .ant-menu-item,
  .ant-menu-item a {
    color: $light-gray;
    &::after {
      border-color: transparent;
    }
    border: None;
    height: 100%;
    font-weight: 600;
    border-bottom: 2px solid transparent;
  }

  .ant-menu-item-active,
  .ant-menu-item:active,
  .ant-menu-item-active a,
  .ant-menu-item:active a {
    background-color: transparent;
    color: $light-gold!important;
    border: None;
    height: 100%;
    border-bottom: 2px solid transparent;
  }

  .ant-menu-horizontal .ant-menu-item-selected,
  .ant-menu-horizontal .ant-menu-item-selected a,
  .ant-menu-horizontal .ant-menu-item-selected svg {
    background-color: transparent;
    color: $dark-gold;
    font-weight: 700;
    border: None;
    height: 100%;
    border-color: transparent;
    border-bottom: 2px solid transparent;
    &::after {
      border: None;
    }
  }

  .ant-menu-vertical .ant-menu-item-selected,
  .ant-menu-vertical .ant-menu-item-selected a,
  .ant-menu-vertical .ant-menu-item-selected svg {
    background-color: transparent;
    color: $dark-gold;
    font-weight: 700;
    border: None;
    height: 100%;
    border-color: transparent;
    border-bottom: 2px solid transparent;
    &::after {
      border: None;
    }
  }
  .ant-menu-item:hover {
    color:$light-gold;
    background-color:transparent;
    border: None !important;
    height: 100%;
    border-color: transparent;
    border-bottom: 2px solid transparent !important;
    &::after {
      border: None !important;
      color: transparent;
    }
  }  
 
}

