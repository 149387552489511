@import "../../../assets/globalStyles/colors.scss";
@import "../../../assets/globalStyles/breakpoints.scss";

.RangePicker{
    width: 100%;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
    background-color: $light-gold;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before{
    background-color: rgba($light-gold,0.2);
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: $light-gold;
}

.ant-picker-range .ant-picker-active-bar{
    background: $light-gold;
}
.ant-picker:hover, .ant-picker-focused{
    border-color: $light-gold;
}

@media only screen and (max-width: $sm) {
    .ant-picker-panels { 
        flex-direction: column;
    } 
}