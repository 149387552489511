@import "../../../assets/globalStyles/colors.scss";

.blackButton {
    .ant-btn {
        vertical-align:middle;
        color: $white-smoke;
        background-color: $dark-gray;
        margin: auto;
        border: $dark-gray;
        &:after {
          border-color: transparent;
          background: transparent;
        }
        &:focus {
          color: $white-smoke;
          background-color: $dark-gray;
          border-color: transparent;
        }
      }
      .ant-btn:hover {
        color: $white-smoke;
        border-color: transparent;
        background-color: $light-gold;
      }
      .ant-btn-primary {
        vertical-align:middle;
        color: $white-smoke;
        background-color: $dark-gray;
        margin: auto;
        border: $dark-gray;
        &:after {
          border-color: transparent;
          background: transparent;
        }
        &:focus {
          color: $white-smoke;
          background-color: $dark-gray;
          border-color: transparent;
        }
      }
      .ant-btn-primary:hover {
        color: $white-smoke;
        border-color: transparent;
        background-color: $light-gold;
      }
}
.goldButton {
  .ant-btn {
      vertical-align:middle;
      color: $white-smoke;
      background-color: $light-gold;
      margin: auto;
      border: $light-gold;
      &:after {
        border-color: transparent;
        background: transparent;
      }
      &:focus {
        color: $white-smoke;
        background-color: $light-gold;
        border-color: transparent;
      }
    }
    .ant-btn:hover {
      color: $white-smoke;
      border-color: transparent;
      background-color: $dark-gray;
    }
    .ant-btn-primary {
      vertical-align:middle;
      color: $white-smoke;
      background-color:  $light-gold;
      margin: auto;
      border: $light-gold;
      &:after {
        border-color: transparent;
        background: transparent;
      }
      &:focus {
        color: $white-smoke;
        background-color:  $light-gold;
        border-color: transparent;
      }
    }
    .ant-btn-primary:hover {
      color: $white-smoke;
      border-color: transparent;
      background-color: $dark-gray;
    }
}