@import "../../../assets/globalStyles/colors.scss";

.rentSteps{
    div.ant-steps-item.ant-steps-item-process.ant-steps-item-active::before{
        background-color: $light-gold;
    }

    div.ant-steps-item.ant-steps-item-process.ant-steps-item-active > div > div.ant-steps-item-icon{
        background-color: $light-gold ;
        border-color: $light-gold;
        > span {
            color: white !important;
        }
    }
    div :hover > {
        div.ant-steps-item-icon{
            border-color: $light-gold !important;
            > span {
                color: $light-gold !important;
            }
        }
        div.ant-steps-item-content{
            > div.ant-steps-item-title, div.ant-steps-item-description {
                color: $light-gold !important;
            }
        }
    }
    div.ant-steps-item.ant-steps-item-finish > div > {
        div.ant-steps-item-icon{
            border-color: $light-gold !important;
            > span {
                color: $light-gold !important;
            }
        }
    }
    
}
div.ant-steps-navigation .ant-steps-item::before{
    background-color: $light-gold !important;
}