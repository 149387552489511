@import "../../../assets/globalStyles/colors.scss";

.catalogueGrid{ 
    overflow-x: hidden;
    margin: auto;
    display: grid;
      .catalogueImage{
      vertical-align: middle;
      margin-left: 200px;
      max-width: 75vw;
      grid-area: 1 / 1;
    }
    .catalogueCard {
      width: fit-content;
      height: fit-content;
      margin-bottom: auto;
      margin-top: auto;
      background-color: $light-gold;
      color: $white-smoke;
      border: transparent;
      padding: 5%;
      grid-area: 1 / 1;
      .title {
        color: $dark-gray
      }
    }
  }