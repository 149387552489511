@import "../../../assets/globalStyles/colors.scss";
@import "../../../assets/globalStyles/breakpoints.scss";


.cardGrid {
    margin: auto;
    width: 85%;
    color: $dark-gray;
    background-color: transparent;
    border: transparent;
    max-width:  925px;
    @media only screen and (min-width: $lg) {
    width: 70%;
    }
    @media only screen and (min-width: $xl) {
    width: 65%;
    }
    .title {
    color: $dark-gray
    }
    .grayCard {
    width: 99%;
    text-align: justify;
    background-color: $dark-gray;
    color: $white-smoke;
    margin: 0.5%;
    padding: 5%;
    }
    .rowStyle {
    height: max-content;
    .goldCard {
        width: 49%;
        text-align: justify;
        background-color: $dark-gold;
        color: $white-smoke;
        margin: 0.5%;
        padding: 5%;
        @media only screen and (max-width: $md) {
        width: 99%;
        }
    }
    .whiteCard {
        width: 99%;
        text-align: justify;
        background-color: $warm-white;
        color: $dark-gray;
        margin: 0.5%;
        padding: 5%;
        @media only screen and (max-width: $md) {
        width: 99%;
        }
    }
    }
}
