@import "../../../assets/globalStyles/colors.scss";



  .layoutContainer {
    min-height: 100vh;
    .layoutContent {
      overflow: auto;
      background-color: $white-smoke;
      padding-top: 65px; 

    }
    .layoutHeader {
      background-color:$dark-gray;
      margin: auto;
      height: max-content;
      width: 100%;
      padding-left: 2%;
      padding-right: 2%;
    }
    .layoutFooter{
      padding: 0 0 0 0;
      position: relative;
    }
  }

