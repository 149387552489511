.centeredContent {
    margin-left: auto;
    margin-right: auto;
    max-width: 60vw;
  }
  .contentMarginTop{
      margin-top: 6vh;
  }
  .siderCollapse {
    overflow-y: scroll;
    max-height: calc(100vh - 252px);
    @media screen and ( max-height: 864px ) {
      max-height: 100vh;
    }
  }