@import "../../../assets/globalStyles/colors.scss";

.endButton{
  text-align: right;
  margin-top: 3vh;
  min-width: 7vw;
}
.termsContainer{
  padding: 3%;
  padding-bottom: 0%;
  :global(.ant-checkbox-checked .ant-checkbox-inner ){
      background-color: $light-gold;
      border-color: $light-gold;
    .after {
      border-color: transparent;
      background: transparent;
    }
    .focus {
      border-color: transparent;
      background: transparent;
    }
  }
}

.smallLetter{
  font-weight: lighter;
  margin-top: 1%;
  text-align: justify;
}

.termsLink {
  color:$dark-gold;
}
.termsLink:hover {
  color:$light-gold;
}
