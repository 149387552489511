@import "../../../assets/globalStyles/colors.scss";
@import "../../../assets/globalStyles/breakpoints.scss";

.voucherCard{
    margin: auto;
    margin-top: 8%;
    padding-bottom: 2%;
    min-width: fit-content;
    width: 60vw;
    background-color: $warm-white;
    min-height: fit-content;
    justify-content: space-around;
    text-align: center;
    max-width: 700px;
    .iconRow{
        width: 100%;
        .iconContainer{
            .successIcon{
                width: 100%;
                height: 100%;
                background-color: $light-gold;
                color: $white-smoke;
                font-size: 100px;
                padding: 20px;
                padding-top: 40px;
            }
            .failureIcon{
                width: 100%;
                height: 100%;
                background-color: $dark-gray;
                color: $warm-white;
                font-size: 100px;
                padding: 20px;
                padding-top: 40px;
            }
        }
        .successTitle{
            width: 100%;
            height: 100%;
            background-color: $light-gold;
            color: $white-smoke;
            font-size: xx-large;
            font-weight: 600;
            @media only screen and (max-width: $md) {
                font-size: x-large;
                }
        
        }
        .failureTitle{
            width: 100%;
            height: 100%;
            background-color: $dark-gray;
            color: $warm-white;
            font-size: xx-large;
            font-weight: 600;
            @media only screen and (max-width: $md) {
                font-size: x-large;
                }
        }
    }
    .infoRow{
        width: 100%;
        padding: 3%;
        font-size: large;
        padding-left: 15%;
        padding-right: 15%;
        @media only screen and (max-width: $md) {
            padding-left: 10%;
            padding-right: 10%;
            }
        .dataRow{
            width: 100%;
            .icon {
                text-align: center;
            }
            .label {
                text-align: left;
            }
            .value {
                text-align: right;
            }
        }
    }
    .buttonRow{
        width: 100%;
        align-items: center;
        padding: 4%;
        padding-top: 2%;
    }
}