.FooterContainer{
    height: 15vh;
}

.FooterCol{
    height: 100%;
    width: 100%;
}

.Corporate{
    @extend .FooterCol;
    background-color: #444443;
}

.Brands{
    @extend .FooterCol;
    background-color: #EEEEEE;
}

.FullSize{
    height: 100%;
    width: 100%;
}

.FooterPadder{
    padding-top: 22px;
    padding-left: 35px;
    padding-right: 35px;
}

.FooterLink{
    color: white;
    :hover{
        color: white;
    }
}

.BrandLink{
    color: #444443;
    :hover{
        color: white;
    }
}