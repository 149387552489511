@import "../../assets/globalStyles/colors.scss";
@import "../../assets/globalStyles/breakpoints.scss";

.fleetViewTitleContainer{
    text-align: center;
    margin-top: 20px;
    .title {
        color: $dark-gray;
    }
}
.brandsButtonsContainer{
    margin: auto;
    text-align: center;
    padding-top: 1%;
    width: 100%;
    .brandButton {
        font-weight: bold;
        color: $dark-gray;
        min-width: 201px;
        background-color: $white-smoke;     
    }
}

.carModelsViewContainer {
    padding-top: 5%;
    margin: auto;
    .carModelcard {
        padding-bottom: 3%;
    }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: $dark-gold;
    border-color: $dark-gold;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 0;
    color: $white-smoke;
    background: $light-gold;
    border-color: $dark-gold;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: $dark-gold;
    border-color: $dark-gold;
  }
