@import "./colors.scss";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-modal{
  background: $dark-gray;
}
.ant-modal-body{
  background: $white-smoke;
  .ant-btn-primary {
    vertical-align:middle;
    color: $white-smoke;
    background-color: $dark-gray;
    margin: auto;
    &:after {
      border-color: transparent;
      background: transparent;
    }
    &:focus {
      color: $white-smoke;
      background-color: $dark-gray;
      border-color: transparent;
    }
  }
  .errorCode {
    color: gray;
  }
  .ant-btn-primary:hover {
    color: $white-smoke;
    border-color: transparent;
    background-color: $light-gold;
  }
  img{
    max-width: 90vw;
    max-height: 90vh;
  }
}

h1 {
  color: $dark-gray;
  font-size: 26px;
  font-weight: 500;
  margin: 0 !important;
}

h2 {
  color: $dark-gray;
  font-size: 22px;
  font-weight: 400;
  margin: 0 !important;
}

h3 {
  color: $dark-gray;
  font-size: 20px;
  font-weight: 400;
  margin: 0 !important;
}

h4 {
  color: $dark-gold;
  font-size: 16px;
  font-weight: 400;
}

p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
}

a {
  color: $dark-gold;
  font-size: 14px;
  font-weight: 400;
}


.ant-radio-checked .ant-radio-inner::after {
  background-color: $light-gold;
}


.ant-radio-checked > span:nth-child(2){
  
  border-color: $light-gold;
}