@import "../../../assets/globalStyles/colors.scss";

.subsidiariesContent {
  text-align: center;
  align-items: center;
  background-color: $warm-white;
  height: 480px;
  width: 100% !important;
  max-width: 370px !important;
  margin: auto;
}
.subsidiariesText {
  max-width: 86%;
  margin: auto;
}
.subsidiariesIcon {
  font-size: x-large;
  color: $dark-gray;
}
.subsidiariesName {
  font-size: medium;
  font-weight: bold;
  min-width: fit-content;
  margin: auto;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 5%;
}
.subsidiariesInfo {
  flex-direction: row;
  min-width: fit-content;
  margin: auto;
  align-items: center;
  text-align: center;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 5%;
}
