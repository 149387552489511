@import "../../../assets/globalStyles/colors.scss";

.titleContainer{
  margin-top: 3%;
  margin-bottom: 3%;
  .title {
      color: $dark-gray;
  }
}

.centered{
  text-align: center;
}
