@import "../../../assets/globalStyles/colors.scss";
@import "../../../assets/globalStyles/breakpoints.scss";


.rentDrawer {  
  .ant-drawer-content-wrapper{
    max-width: 90vw;
  }
  text-align: center;
  .ant-drawer {
      background-color: transparent
      }
  .ant-drawer-header{
      text-align: center;
      background-color: $warm-white
  }
  .ant-drawer-title{
      color: $dark-gray;
      font-weight: 700;
  }
  .ant-drawer-body {
      background-color: $warm-white;
      width: max-content;
      max-width: 90vw;
  }
}
.reservationContainer {
    background-color: $warm-white;
    height: 100vh;
}
.carSearchFilterButton {
    padding-top: 5%;
    padding-left: 5%;
}
.noResultsCarModelCard {
    padding-top: 1%;
    text-align: center;
}

.ant-spin{
    color: $dark-gold;
}

.Loader {
    font-size: 125px;
    @media only screen and (max-width: $md) {
      font-size: 75px;
    }
    margin-top: 30%;
    margin-left: 30vw;
  }