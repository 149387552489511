@import "../../../assets/globalStyles/colors.scss";

.carousel {
  .carouselContent{
    height: auto;
    text-align: center;
    background: $white-smoke;
    .carouselImage{
      vertical-align: middle;
      margin: auto;
      width: 100vw;
    }
  }
}
.ant-carousel .slick-dots-bottom{
  z-index: 0;
}