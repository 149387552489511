@import "../../assets/globalStyles/colors.scss";

.app {
    text-align:  justify;
    align-items: center;
    .titleContainer{
        text-align: center;
        margin-top: 20px;
        .title {
            color: $dark-gray;
        }
    }
    .subsidiariesContainer{
        padding-top: 2%;
        text-align: center;
        .title {
            color: $dark-gray;
        }
    }
    .card {
        padding-left: 2%;
        padding-right: 1%;
        padding-bottom: 1%;
    }
height: fit-content;}